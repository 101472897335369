<template>
  <div class="deviceLocation">
    <BSJMap ref="mapRef" :center="centerLng" @loaded="onMapLoad">
      <!-- 聚合点 -->
      <MapClusterer
        ref="clusterObj"
        :points="clusterPoints"
        :minClusterSize="minClusterSize"
        pointKey="key"
        :markerOffset="markerOffset"
        @markerClick="onMarkerClick"
      >
        <template #marker="{ point }">
          <!-- <Iconfont
            :name="getProductTypeIconName(point.data.machineryProductType, point.data.machineryEquipmentType)"
            :size="32"
            :rotate="point.data.F || 0"
          ></Iconfont>-->
          <!-- <Iconfont name="icon-a-zu517" :size="32" :rotate="point.data.F || 0"></Iconfont> -->
          <Iconfont
            :size="48"
            :height="52"
            :name="vehicleIconName(point.data)"
          ></Iconfont>
        </template>
      </MapClusterer>

      <!-- 展示地图信息窗体 -->
      <InfoWindow
        ref="windowObj"
        v-model="infoVisible"
        :lngLat="windowPoint"
        :offset="[-4, -15]"
        :width="390"
        :height="0"
        autoMove
      >
        <div style="border-bottom: 1px solid #F5F5F5;">
          <div class="cell">
            <div class="cell-label">最新位置</div>
            <div class="cell-value">更新于{{ currentVehicle.PT
                ? dayjs(currentVehicle.PT).format("YYYY-MM-DD HH:mm:ss")
                : (currentVehicle.R ? dayjs(currentVehicle.R).format("YYYY-MM-DD HH:mm:ss") : '--') }}</div>
          </div>
          <div class="cell">
            <img src="@/assets/images/newRiskControl/moduel-dz-icon.png" class="cell-icon" alt="" srcset="">
            <div class="cell-value2" style="flex:1">
              <AddressText v-if="currentVehicle.X && currentVehicle.Y"
                :autoScroll="false"
                :lng="currentVehicle.X"
                :lat="currentVehicle.Y"
              ></AddressText>
              <span v-else>--</span>
            </div>
          </div>
        </div>
        <div class="navigation" @click="handleNavigation">
          <img src="@/assets/images/newRiskControl/xq-dh-icon.png" class="navigation-icon" alt="" srcset="">
          <span>导航</span>
        </div>
      </InfoWindow>
    </BSJMap>
  </div>
</template>

<script>
import BSJMap, { InfoWindow, MapClusterer } from "@/components/AMap";
import AddressText from "@/components/AddressText";
import { getDwellTime3 } from "@/utils/formatDate";

import GPS from "@/utils/address";
import { mapState } from "vuex";

// import { getProductTypeIconName } from '@/utils/getProductTypeIconName';

import { amapGetIp } from "@/api/weather";
import vehiclePicture from "@/utils/vehiclePicture";
import { newGetCompleteAddress } from '@/components/AMap/utils';
import dayjs from 'dayjs';

export default {
  name: "RtpMap",
  components: {
    BSJMap,
    MapClusterer,
    InfoWindow,
    AddressText,
  },
  model: {
    prop: "currentVehicle",
  },
  props: {
    /**
     * 聚合的最小数量。默认值为2，即小于2个点则不能成为一个聚合
     */
    minClusterSize: {
      type: Number,
      default: 10,
    },
    currentVehicle: {
      type: Object,
      default: null,
    },
    vehicleList: {
      type: Array,
      default() {
        return [];
      },
    },
    speakVehicle: {
      type: Object,
      default: null,
    },
  },
  data() {
    // 地图实例
    this.mapObj = null;
    return {
      markerOffset: [0, 0],
      infoVisible: false,
      centerLng: null,
      ruleIsOn: false,
    };
  },
  computed: {
    // 聚合点
    clusterPoints() {
      const { vehicleList } = this;
      let vLIst = vehicleList.filter(v=> v.Y && v.X);
      return vLIst.map((obj, indd) => {
        const point = GPS.delta(obj.Y, obj.X);
        return {
          key: obj.key || indd,
          lng: point.lon || '',
          lat: point.lat || '',

          // lng: 116.40121,
          // lat: 39.907795,

          data: obj,
        };
      });
    },
    getStateLabel() {
      const { currentVehicle } = this;
      if (currentVehicle) {
        // if (currentVehicle.Z === 2 && currentVehicle.isCommercialVehicles) return "开工";
        // if (currentVehicle.Z === 4 && currentVehicle.isCommercialVehicles) return "停工";
        // if (currentVehicle.Z === 0) return "怠速";
        // if (currentVehicle.Z === 1) return "行驶";
        // if (currentVehicle.Z === 2) return "作业";
        // if (currentVehicle.Z === 3) return "通电";
        // if (currentVehicle.Z === 4) return "熄火";
        // if (currentVehicle.Z === 5) return "离线";
        if (currentVehicle.Z === 0) return "停工";
        if (currentVehicle.Z === 1) return "开工";
        if (currentVehicle.Z === 5) return "离线";
        return "从未上线";
      }
      return "从未上线";
    },
    windowPoint() {
      // this.infoVisible = true;
      // return [116.40121, 39.907795];
      const { currentVehicle } = this;
      if (!currentVehicle || !currentVehicle.Y) {
        this.infoVisible = false;
        return [];
      }
      const point = GPS.delta(currentVehicle.Y, currentVehicle.X);
      this.centerLng = [point.lon, point.lat];
      return [point.lon, point.lat];
    },
    ...mapState(["realIP"]),
  },
  watch: {
    clusterPoints(val) {
      if (val.length <= 0) {
        this.infoVisible = false;
      }
    },
    currentVehicle: {
      handler(val) {
        const { clusterPoints } = this;
        if (!val || clusterPoints.length <= 0) {
          this.infoVisible = false;
          return;
        }
        this.infoVisible = true;
      },
      deep: true,
    },
    windowPoint: {
      handler() {
        this.$nextTick(()=>{
          this.panToCenter();
        })
      },
      deep: true
    }
  },
  beforeMount() {},
  created() {
    // amapGetIp({
    //   key: "60f85fa7d1c353b0a2ffb92083e365f0",
    //   ip: this.realIP,
    // }).then((res) => {
    //   // amapGetIp({ key: '60f85fa7d1c353b0a2ffb92083e365f0',ip: '113.71.214.232' }).then(res=>{
    //   if (res.rectangle && res.rectangle.length > 0) {
    //     let rectangle = res.rectangle.split(";");
    //     let rectangle1 = rectangle[0].split(",");
    //     let rectangle2 = rectangle[1].split(",");
    //     let Lng =
    //       parseFloat(rectangle1[0]) +
    //       (parseFloat(rectangle1[0]) - parseFloat(rectangle2[0]));
    //     let Lat =
    //       parseFloat(rectangle1[1]) +
    //       (parseFloat(rectangle1[1]) - parseFloat(rectangle2[1]));
    //     this.centerLng = [Lng, Lat];
    //   }
    // });
  },
  mounted() {},
  beforeDestroy() {},
  methods: {
    dayjs,
    // getProductTypeIconName,
    panToCenter() {
      const { windowPoint } = this;
      const { mapRef } = this.$refs;
      if (mapRef && windowPoint.length) {
        mapRef.panTo(windowPoint);
        this.$forceUpdate();
      }
    },
    async setFitView() {
      await this.$nextTick();

      const { clusterObj } = this.$refs;
      if (clusterObj) {
        clusterObj.setFitView();
      }
    },
    onMarkerClick(obj) {
      this.infoVisible = true;
      this.$emit("input", obj.data);
    },
    // 地图加载完成
    onMapLoad(map) {
      this.mapObj = map;
    },
    // 测距工具
    hadnleRuler() {
      if (this.ruleIsOn) return;

      let startMarkerOptions = {
        icon: new AMap.Icon({
          size: new AMap.Size(19, 31), //图标大小
          imageSize: new AMap.Size(19, 31),
          image: "https://webapi.amap.com/theme/v1.3/markers/b/start.png",
        }),
      };
      let endMarkerOptions = {
        icon: new AMap.Icon({
          size: new AMap.Size(19, 31), //图标大小
          imageSize: new AMap.Size(19, 31),
          image: "https://webapi.amap.com/theme/v1.3/markers/b/end.png",
        }),
        offset: new AMap.Pixel(-9, -31),
      };
      let midMarkerOptions = {
        icon: new AMap.Icon({
          size: new AMap.Size(19, 31), //图标大小
          imageSize: new AMap.Size(19, 31),
          image: "https://webapi.amap.com/theme/v1.3/markers/b/mid.png",
        }),
        offset: new AMap.Pixel(-9, -31),
      };
      let lineOptions = {
        strokeStyle: "solid",
        strokeColor: "#FF33FF",
        strokeOpacity: 1,
        strokeWeight: 2,
      };
      var rulerOptions = {
        startMarkerOptions: startMarkerOptions,
        midMarkerOptions: midMarkerOptions,
        endMarkerOptions: endMarkerOptions,
        lineOptions: lineOptions,
      };
      const that = this;
      this.mapObj.plugin(["AMap.RangingTool"], function () {
        const ruler = new AMap.RangingTool(that.mapObj, rulerOptions);
        ruler.turnOn();

        that.ruleIsOn = true;

        AMap.event.addListener(ruler, "end", function (e) {
          ruler.turnOff();

          that.ruleIsOn = false;
        });
      });
    },
    vehicleIconName(val) {
      return vehiclePicture(
        val.machineryProductType,
        val.machineryEquipmentType,
        val.Z,
        true
      );
    },
    handleChange() {
      this.$emit("change");
    },
    getDwellTime(e,ind) {
      return getDwellTime3(e * 1000)[ind]
    },
    async handleNavigation() {
      try {
        if (!this.currentVehicle.X || !this.currentVehicle.Y) {
          this.$message.error('当前定位无法导航')
          return false;
        }
        const { secondId } = await newGetCompleteAddress(this.currentVehicle.X, this.currentVehicle.Y);
        window.open('https://www.amap.com/place/' + secondId);
      } catch (error) {
        
      }
    }
  },
};
</script>

<style lang="scss" scoped>
.deviceLocation {
  position: relative;
  width: 100%;
  height: 376px;
  padding: 0 24px 16px;
  border-bottom: 1px solid #707070;
  ::v-deep .window_outer {
    width: 310px !important;
  }
  ::v-deep .WindowContent {
    width: 310px;
    padding: 16px;
    padding-bottom: 0;
    border: none !important;
    background: rgba(255,255,255,0.9);
    box-shadow: 0px 3px 6px 1px rgba(51,51,51,0.1);
    border-radius: 10px 10px 10px 10px;
    .cell {
      display: flex;
      justify-content: space-between;
      margin-bottom: 16px;
      &-label {
        font-size: 14px;
        color: #333333;
      }
      &-value {
        font-size: 12px;
        color: #999999;
      }
      &-icon {
        width: 18px;
        height: 18px;
        margin-right: 5px;
      }
      &-value2 {
        font-size: 14px;
        color: #333333;
      }
    }
    .navigation {
      height: 40px;
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 14px;
      color: #0080FF;
      cursor: pointer;
      &-icon {
        width: 18px;
        height: 18px;
        margin-right: 4px;
      }
    }
  }
}
::v-deep .amap-toolbar {
  display: none !important;
}
</style>