<template>
  <div class="weather-box" v-loading="isLoading">
    <div class="weather-box-o">
      <div class="weather-box-o-t">
        <div class="weather-box-o-t-item" :class="{ 'weather-box-o-t-current': index === 0 }" v-for="item,index in weatherList" :key="index">
          <div class="weather-box-o-t-item-time">{{ index === 0 ? '今天' : item.date || '--' }}</div>
          <img :src="getWeatherIcon(item.dayweather)" class="weather-box-o-t-item-icon" alt="" srcset="">
          <div class="weather-box-o-t-item-type">{{ item.dayweather || '--' }}</div>
          <div class="weather-box-o-t-item-wd">{{ `${item.nighttemp}~${item.daytemp}℃` || '--' }}</div>
          <div class="weather-box-o-t-item-f">{{ `${item.daywind}${item.daypower || '--'}级` || '--' }}</div>
          <!-- <div class="weather-box-o-t-item-dj">{{ item.sss || '--' }}</div> -->
        </div>
        <div v-if="!weatherList || !weatherList.length">无定位信息无法获取天气</div>
      </div>
    </div>
  </div>
</template>

<script>
import { deviceWeather } from "@/api/SystemData";
import { getWeatherIcon } from "@/utils/weatherTool.js";
export default {
  model: {
    prop: "currentVehicle",
  },
  props: {
    currentVehicle: {
      type: Object,
      default: null,
    },
  },
  data() {
    return {
      weatherList: [],
      isLoading: false,
      getWeatherIcon
    }
  },
  created() {
    // this.getList();
  },
  methods: {
    async getList() {
      try {
        let data = {
          vehicleId: this.currentVehicle.vehicleId,
        };
        this.isLoading = true;
        let res = await deviceWeather(data);
        this.isLoading = false;
        if (res.code == 200) {
          this.weatherList = res.data;
        }else {
          this.weatherList = []
        }
      } catch (error) {
        this.isLoading = false;
        console.log(error);
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.weather-box {
  width: 100%;
  height: 412px;
  // height: 434px;
  display: flex;
  align-items: center;
  &-o {
    width: 100%;
    // height: 320px;
    margin: 0 24px;
    background-color: rgba(242, 247, 255, .5);
    border-radius: 40px;
    &-t {
      display: flex;
      align-items: center;
      justify-content: space-around;
      // height: 280px;
      height: 258px;
      margin: 14px;
      background: #F2F7FF;
      border-radius: 40px;
      &-item {
        text-align: center;
        padding: 24px 0;
        width: 140px;
        &-time {
          font-size: 14px;
          color: #333333;
        }
        &-icon {
          display: block;
          width: 36px;
          height: 36px;
          margin: auto;
          margin-top: 26px;
        }
        &-type {
          font-size: 14px;
          color: #333333;
          margin-top: 4px;
        }
        &-wd {
          font-size: 14px;
          color: #333333;
          margin-top: 26px;
        }
        &-f {
          font-size: 14px;
          color: #333333;
          margin-top: 26px;
        }
        &-dj {
          width: 44px;
          height: 23px;
          line-height: 23px;
          text-align: center;
          font-size: 14px;
          color: #FFFFFF;
          margin: auto;
          margin-top: 26px;
          background: #00BC0D;
          border-radius: 15px;
        }
      }
      &-current {
        padding: 79px 0;
        // padding: 59px 0;
        height: 340px;
        // height: 362px;
        font-weight: bold;
        background: #FFFFFF;
        box-shadow: 0px 0px 12px 1px rgba(0,74,148,0.15);
        border-radius: 70px;
      }
    }
  }
}
</style>