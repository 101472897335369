<template>
  <div class="hour" v-loading="isLoading" style="margin-top: -10px">
    <div class="hour-head">
      <div class="hour-head-item">
        <div style="font-weight: bold; font-size: 14px; color: #333333">
          {{ tableTotal.sn || "--" }}
        </div>
      </div>
      <div class="hour-head-item">
        <div class="hour-head-twins" style="margin-left: 8px">
          <div class="hour-head-twins-identical hour-head-twins-left">
            上报时间{{ tableTotal.devTime }}
          </div>
        </div>
      </div>
    </div>
    <div class="hour-content">
      <SCTable
        ref="SCTable"
        :tableData="tableData"
        :total="0"
        :isPagination="false"
        @changeConditions="onChangeConditions"
      >
        <el-table-column prop="index" label="序号" width="70">
          <template slot-scope="scope">
            {{ scope.$index + 1 }}
          </template>
        </el-table-column>
        <el-table-column prop="key" label="故障类型">
          <template slot-scope="scope">
            {{ scope.row.key || "--" }}
          </template>
        </el-table-column>
        <el-table-column prop="policyCompany" label="故障内容">
          <template slot-scope="scope">
            <span
              :style="{ color: scope.row.val != '无异常' ? '#F64A2D' : '#999' }"
              >{{ scope.row.val || "--" }}</span
            >
          </template>
        </el-table-column>
      </SCTable>
    </div>
  </div>
</template>

<script>
import dayjs from "dayjs";
import SCTable from "@/views/RealTimePositioning/components/table";
import { onlineExport, deviceFault } from "@/api/SystemData";
import { hasPerms, dataPermissions } from "@/utils/auth";
import { appConfig } from "@/config/appConfig";
export default {
  model: {
    prop: "currentVehicle",
  },
  props: {
    currentVehicle: {
      type: Object,
      default: null,
    },
  },
  data() {
    return {
      bodyData: {
        dateTime: [dayjs().format("YYYY-MM-DD"), dayjs().format("YYYY-MM-DD")],
        status: "",
        alarmTypeIdList: "",
      },
      levelOptions: [
        {
          label: "全部",
          value: "",
        },
        {
          label: "离线",
          value: "2",
        },
        {
          label: "在线",
          value: "1",
        },
      ],
      typeOptions: [],
      endDateTime: dayjs().format("YYYY-MM-DD"),
      tableData: [],
      tatolTime: "0时0分",
      conditionsData: {
        pageIndex: 1,
        pageSize: 10,
      },
      tableTotal: 0,
      chartsData: {},
      editData: {},
      isVisible: false,
      isImport: false,
      visibleDialog: false,
      alarmiProp: {
        selects: [], // 多选报警
        alarmParmas: {}, // 单个报警数据
        type: 0, //报警类型 0:常规 1：adas报警
      },
      isLoading: false,
    };
  },
  components: { SCTable },
  watch: {
    bodyData: {
      handler(v) {
        this.getList("reset");
      },
      deep: true,
    },
    currentVehicle: {
      handler(v, old) {
        this.getList();
      },
      immediate: true,
      deep: true,
    },
  },
  created() {
    this.getList();
  },
  methods: {
    async getList(type) {
      try {
        if (type == "reset") {
          this.$refs.SCTable.handleCurrentChange(1);
          return false;
        }
        if (!this.currentVehicle.vehicleId) return;
        let api = deviceFault;
        let data = {
          vehicleId: this.currentVehicle.vehicleId,
          pageIndex: this.conditionsData.pageIndex,
          pageSize: this.conditionsData.pageSize,
        };
        this.isLoading = true;
        let res = await api(data);
        this.isLoading = false;
        if (res.code == 200 && res.data.fault) {
          let list = [];
          for (const key in res.data.fault) {
            list.push({
              key,
              val: res.data.fault[key],
            });
          }
          this.$nextTick(() => {
            this.tableData = list || [];
          });
          this.tableTotal = res.data;
        }
      } catch (error) {
        this.isLoading = false;
        console.log(error, "--------------");
      }
    },
    onChangeConditions(e) {
      this.conditionsData = e;
      this.getList();
    },
    hadnleExport() {
      let data = {
        vehicleId: this.currentVehicle.vehicleId,
        pageIndex: this.conditionsData.pageIndex,
        pageSize: this.conditionsData.pageSize,
        status: this.bodyData.status,
      };
      if (this.bodyData.dateTime && this.bodyData.dateTime.length) {
        data.beginDateTime = dayjs(this.bodyData.dateTime[0]).format(
          "YYYY-MM-DD 00:00:00"
        );
        data.endDateTime = dayjs(this.bodyData.dateTime[1]).format(
          "YYYY-MM-DD 23:59:59"
        );
      }
      onlineExport(data);
    },
    handleNextDate(e) {
      if (e > 0 && this.bodyData.dateTime == this.endDateTime) return false;
      this.bodyData.dateTime = dayjs(this.bodyData.dateTime)
        .add(e, "day")
        .format("YYYY-MM-DD");
    },
    async handleAlarmDetails(item, type) {
      if (type == "policyNo") {
        this.$router.push({
          path:
            `/routerPolicyManagement/${
              item.category == 1
                ? "ConstructionMachinery"
                : item.category == 2
                ? "CarInsurance"
                : "Insurance"
            }/policyDetails/` + item.policyId,
        });
      } else {
        this.$router.push({
          path: "/routerClaimManagement/claimDetails/" + item.id,
        });
      }
    },

    onHandleClick(row) {
      this.alarmiProp.selects = [];
      this.visibleDialog = true;
      this.alarmiProp.alarmParmas = {
        plate: row.plate, // 设备名称
        vehicleId: row.vehicleId, // 车辆Id
        groupName: row.groupName, // 车组名称
        groupId: row.groupId, // 车组Id
        alarmTime: row.timeBegin, // 报警时间
        alarmTypeEn: row.alarmTypeEn,
        alarmType: row.alarmType, // 报警类型
        alarmNameEnglish: row.alarmNameEnglish, // 报警类型
        alarmTypeCh: row.type,
        alarmAddress: row.address, // 报警地址
        alarmSourceStr: {
          1: "车载终端",
          2: "企业监控平台",
          3: "政府监管平台",
          9: "其他",
        }[row.alarmSource],
        alarmSource: row.alarmSource, // 报警来源
        alarmId: row.alarmId,
      };
    },
    handleDownloadSttachment(item) {
      // safetyManageDam({alarmId: item.alarmId})
      window.open(
        appConfig.base2GUrl2 +
          `/safetyManage/dam?alarmId=${item.alarmId}&token=${this.$store.state.appToken}&platform=PC`,
        "_blank"
      );
    },
    // 模态框关闭
    closeDialog(model) {
      this.visibleDialog = model;
    },
    // 指令下发后刷新数据
    onCmdSendChange() {
      this.isImport = false;
      this.getList();
    },
    handleHasPerms(e) {
      return hasPerms(e);
    },
  },
};
</script>

<style lang="scss" scoped>
.hour {
  background: #ffffff;
  height: 100%;
  padding: 0 24px;
  &-head {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 14px;
    &-item {
      display: flex;
      align-items: center;
    }
    &-refresh {
      display: flex;
      align-items: center;
      justify-content: center;
      cursor: pointer;
      width: 54px;
      height: 32px;
      font-weight: 400;
      font-size: 12px;
      color: #333333;
      background: #f5f5f5;
      border-radius: 4px 4px 4px 4px;
      border: 1px solid #dee3e6;
      img {
        width: 14px;
        height: 14px;
        margin-right: 2px;
        margin-top: -3px;
      }
    }
    &-record {
      display: flex;
      align-items: center;
      justify-content: center;
      height: 32px;
      font-weight: bold;
      font-size: 14px;
      color: #0080ff;
      background: #e6f3ff;
      border-radius: 4px 4px 4px 4px;
      img {
        width: 32px;
        height: 32px;
      }
      div {
        padding: 0 10px;
      }
    }
    &-twins {
      display: flex;
      align-items: center;
      overflow: hidden;
      &-identical {
        display: flex;
        align-items: center;
        margin-right: 8px;
        font-size: 14px;
        color: #333333;
        ::v-deep .el-select {
          margin-left: 8px;
        }
        ::v-deep .el-date-editor {
          width: 169px;
          margin: 0 8px;
        }
        .el-date-b {
          display: flex;
          align-items: center;
          margin-left: 8px;
        }
      }
      &-botton {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 32px;
        height: 32px;
        cursor: pointer;
        background: #f2f7fd;
        border-radius: 4px 4px 4px 4px;
        border: 1px solid #dee3e6;
        &-d {
          cursor: no-drop;
          opacity: 0.8;
        }
      }
      // &-left {
      //   border: 1px solid #DEE3E6;
      //   border-radius: 16px 0 0 16px;
      //   border-right: none;
      // }
      // &-right {
      //   border: 1px solid #DEE3E6;
      //   border-left: none;
      //   border-radius: 0 16px 16px 0;
      // }

      // &-check-list {
      //   border-color: #0080FF;
      //   border-right: 1px solid #0080FF;
      //   color: #0080FF;
      // }
      // &-check-charts {
      //   border-color: #0080FF;
      //   border-left: 1px solid #0080FF;
      //   color: #0080FF;
      // }
    }
  }
  &-content {
    height: 314px;
    &-status {
      width: 66px;
      height: 30px;
      background: rgba(204, 204, 204, 0.1);
      border-radius: 2px 2px 2px 2px;
      border: 1px solid rgba(204, 204, 204, 0.2);
      line-height: 30px;
      text-align: center;
      font-size: 14px;
      color: #cccccc;
      border-radius: 2px;
    }
    &-status1 {
      font-size: 14px;
      color: #3470ff;
      background: rgba(52, 112, 255, 0.1);
      border-radius: 2px 2px 2px 2px;
      border: 1px solid rgba(52, 112, 255, 0.2);
    }
    &-status2 {
      font-size: 14px;
      color: #fca118;
      background: rgba(252, 161, 24, 0.1);
      border-radius: 2px 2px 2px 2px;
      border: 1px solid rgba(252, 161, 24, 0.2);
    }
    &-status3 {
      background: #f64a2d;
    }
  }
}
</style>
