<template>
  <div class="car-d">
    <div class="card-box" @click="handleShowDetails">
      <Iconfont
        class="card-box-img"
        :size="72"
        :name="`https://yiligps.oss-cn-shenzhen.aliyuncs.com/ui-show/${
          carImgList.includes(
            equipmentData.machineryProductType +
              equipmentData.machineryEquipmentType
          )
            ? equipmentData.machineryProductType +
              equipmentData.machineryEquipmentType
            : carImgList.includes(equipmentData.machineryProductType)
            ? equipmentData.machineryProductType
            : 'excavating_machinery'
        }.png`"
      ></Iconfont>
      <div class="card-box-content">
        <div class="card-box-content-title">
          <div class="card-box-content-title-name" style="word-wrap: break-word;overflow: hidden;">
             <!-- text-ellipsis -->
            {{ equipmentData.plate || "--" }}
          </div>
          <div
            class="card-box-content-title-status"
            :class="{
              'card-box-content-title-status2': equipmentData.isOnline,
            }"
          >
            {{
              equipmentData.isOnline
                ? "在线"
                : `离线` +
                  `${
                    equipmentData.lastDeviceTime
                      ? "(" +
                        (getDwellTime5(equipmentData.lastDeviceTime) || "--") +
                        ")"
                      : ""
                  }`
            }}
          </div>
        </div>
        <slot name="special">
          <div class="card-box-content-special" v-if="modelKey == 'address'">
            <img
              class="card-box-content-special-icon"
              src="@/assets/images/newRiskControl/moduel-dz-icon.png"
              alt=""
              srcset=""
            />
            <div class="card-box-content-special-name">
              {{ equipmentData.address || "--" }}
            </div>
          </div>
          <div class="card-box-content-special-working" v-if="modelKey == 'working'">
            {{ equipmentData.workingStr }}
            <!-- <img
              class="card-box-content-special-icon"
              src="@/assets/images/newRiskControl/moduel-gs-icon.png"
              alt=""
              srcset=""
            />
            <div class="card-box-content-special-name">
              昨日工时：{{
                getDwellTime4(equipmentData.working * 1000) || "--"
              }}
            </div> -->
          </div>
          <div
            class="card-box-content-special-alarm"
            v-if="modelKey == 'alarm'"
          >
            <marquee behavior="scroll">{{
              equipmentData.terminalStatus || ''
            }}</marquee>
            <!-- {{ equipmentData.lastDeviceTime || '--' }}  -->
          </div>
          <div v-if="modelKey == 'alarmNum'">
            <div class="card-box-content-special" style="height: auto">
              <img
                class="card-box-content-special-icon"
                style="margin-top: 0"
                src="@/assets/images/newRiskControl/moduel-gs-icon.png"
                alt=""
                srcset=""
              />
              <div class="card-box-content-special-alone">
                {{ equipmentData.address || "--" }}
              </div>
            </div>
            <div
              class="card-box-content-special"
              style="height: auto; margin-top: 9px"
            >
              <img
                class="card-box-content-special-icon"
                src="@/assets/images/newRiskControl/moduel-gj-icon.png"
                style="margin-top: 0"
                alt=""
                srcset=""
              />
              <div
                class="card-box-content-special-alone"
                style="color: #f86060"
              >
                待处理告警数：{{ equipmentData.alarmCount || "--" }}
              </div>
            </div>
          </div>
          <div v-if="modelKey == 'weather'">
            <div class="card-box-content-special" style="height: auto">
              <img
                class="card-box-content-special-icon"
                style="margin-top: 0"
                src="@/assets/images/newRiskControl/moduel-gs-icon.png"
                alt=""
                srcset=""
              />
              <div class="card-box-content-special-alone">
                {{ equipmentData.address || "--" }}
              </div>
            </div>
          </div>
        </slot>
      </div>
    </div>
    <div class="card-box-content-operation" v-if="isOperation" @click.stop="">
      <div style="font-weight: 400">{{ equipmentData.interveneWay }}</div>
      <el-dropdown @command="handleCommand">
        <el-button class="cl-button" type="primary" size="mini">处理</el-button>
        <el-dropdown-menu slot="dropdown">
          <el-dropdown-item command="1" @click.stop=""
            >确认无风险</el-dropdown-item
          >
          <el-dropdown-item command="2" @click.stop="">误报</el-dropdown-item>
          <el-dropdown-item command="3" @click.stop="">风险点</el-dropdown-item>
          <!-- <el-dropdown-item command="4">电话联系</el-dropdown-item> -->
        </el-dropdown-menu>
      </el-dropdown>
    </div>
    <div class="card-box-content-operation" v-else-if="equipmentType == 8">
      <div style="font-weight: 400">{{ equipmentData.interveneWay }}</div>
      <!-- :disabled="complete ? true : false" -->
      <el-button
        class="cl-button"
        style="padding: 3px 15px"  @click="handleShowDetails"
        type="primary"
        size="mini"
      >
        <div class="cl-button-b">
          <img
            src="@/assets/images/newRiskControl/moduel-spjk-icon.png"
            alt=""
            srcset=""
          />
          实时视频
        </div>
      </el-button>
    </div>
    <div class="card-box-content-operation" v-else-if="equipmentType == 3">
      <div style="font-weight: 400">{{ equipmentData.interveneWay }}</div>
      <div class="card-box-content-weather" style="height: auto">
        <span>今日天气：</span>
        <img :src="getWeatherIcon(equipmentData.weather)" alt="" srcset="" />
        <span>
          {{ equipmentData.weather || "--" }}
        </span>
      </div>
    </div>
    <div
      v-else-if="equipmentData.interveneWay"
      class="card-box-content-operation"
      style="font-weight: 400"
    >
      {{ equipmentData.interveneWay }}
    </div>
  </div>
</template>

<script>
import { getDwellTime5 } from "@/utils/formatDate";
import { riskHandle } from "@/api/SystemData";
import { getWeatherIcon } from "@/utils/weatherTool.js";
export default {
  props: {
    equipmentData: {
      type: Object,
      default: () => {},
    },
    equipmentType: {
      type: [String, Number],
      default: "",
    },
    modelKey: {
      type: String,
      default: "",
    },
    isOperation: {
      type: Boolean,
      default: false,
    },
    conditionObj: {
      type: Object,
      default: () => {},
    },
    complete: {
      type: [String, Number],
      default: "",
    },
  },
  components: {},
  data() {
    return {
      carImgList: [
        "aerial_work_machinery1",
        "aerial_work_machinery2",
        "compaction_machinery",
        "excavating_machinery",
        "excavating_machinery1",
        "hoisting_machinery",
        "hoisting_machinery3",
        "mining_machinery",
        "piling_machinery1",
        "shovel_transport_machinery0",
        "shovel_transport_machinery1",
        "shovel_transport_machinery3",
        "industrial_vehicles1",
        "agriculture_mechanics1",
        "concrete_machinery3",
        "shovel_transport_machinery9",
        "diao_guan_ji0",
        "yi_dong_dian_zhan0",
        "han_jie_gong_zuo_zhan0",
        "zhong_pin_jia_re_che0",
          'new_energy_vehicles0',
      ],
      getDwellTime5,
      getWeatherIcon,
      disposeType: "",
    };
  },
  methods: {
    changeGetList() {
      console.log("处理");
    },
    handleCommand(e) {
      const h = this.$createElement;
      const that = this;
      switch (e) {
        case "1":
          riskHandle({
            vehicleId: that.equipmentData.vehicleId, //车辆id
            plate: that.equipmentData.plate, //车牌
            dataType: that.equipmentType, //数据类型(1:设备离线，2:设备故障，3:天气监控，4:工时监控，5:保单脱保，6：设备出险)
            interveneWay: "确认无风险", //处理方式(确认无风险、误报，风险点)
            remark: "", //处理内容
            createUserId: that.$store.state.userInfo.userId, //处理用户id
            createUser: that.$store.state.userInfo.name, //处理用户名
            projectName: that.conditionObj.projectName, //项目名称
            projectIds: that.conditionObj.projectId, //项目id
            customName: that.conditionObj.customName, //客户名称
          })
            .then((res) => {
              //instance.confirmButtonLoading = false;
              this.$message.success("处理成功");
              this.$emit("changeList");
              //done();
            })
            .catch((err) => {
              //instance.confirmButtonLoading = false;
              this.$message.success("处理失败");
              //done();
            });
          // this.$msgbox({
          //   title: '',
          //   message: h("div", null, [
          //     h(
          //       "img",
          //       {
          //         style:
          //           "display: block;margin: 0 auto 10px;width:60px;height:60px;",
          //         attrs: {
          //           src: require("@/assets/images/newRiskControl/cl-gj-icon.png"),
          //           alt: "Alternative Text",
          //         },
          //       },
          //       null
          //     ),
          //     h("div", { style:'font-weight: bold;font-size: 16px;color: #333333;text-align: center;margin-bottom: 24px;' }, "提示 "),
          //     h("div", { style:'font-size: 14px;color: #333333;text-align: center;margin-bottom: 24px;' }, "此警告确认无风险？"),
          //   ]),
          //   showCancelButton: true,
          //   confirmButtonText: "确定",
          //   cancelButtonText: "取消",
          //   beforeClose: (action, instance, done) => {
          //     if (action === "confirm") {
          //       instance.confirmButtonLoading = true;
          //       instance.confirmButtonText = "执行中...";
          //       riskHandle(
          //         {
          //           "vehicleId": that.equipmentData.vehicleId, //车辆id
          //           "plate": that.equipmentData.plate, //车牌
          //           "dataType": that.equipmentType, //数据类型(1:设备离线，2:设备故障，3:天气监控，4:工时监控，5:保单脱保，6：设备出险)
          //           "interveneWay": "确认无风险", //处理方式(确认无风险、误报，风险点)
          //           "remark": "", //处理内容
          //           "createUserId": that.$store.state.userInfo.userId, //处理用户id
          //           "createUser": that.$store.state.userInfo.name, //处理用户名
          //           "projectName": that.conditionObj.projectName, //项目名称
          //           "projectIds": that.conditionObj.projectId, //项目id
          //           "customName": that.conditionObj.customName //客户名称
          //           }
          //       ).then((res) => {
          //         instance.confirmButtonLoading = false;
          //         this.$message.success('处理成功');
          //         this.$emit('changeList');
          //         done();
          //       }).catch((err) => {
          //         instance.confirmButtonLoading = false;
          //         this.$message.success('处理失败');
          //         done();
          //       });
          //       // setTimeout(() => {
          //       //   done();
          //       //   setTimeout(() => {
          //       //     instance.confirmButtonLoading = false;
          //       //   }, 300);
          //       // }, 3000);
          //     } else {
          //       done();
          //     }
          //   },
          // }).then((action) => {

          // });
          break;
        case "2":
          riskHandle({
            vehicleId: this.equipmentData.vehicleId, //车辆id
            plate: this.equipmentData.plate, //车牌
            dataType: this.equipmentType, //数据类型(1:设备离线，2:设备故障，3:天气监控，4:工时监控，5:保单脱保，6：设备出险)
            interveneWay: "误报", //处理方式(确认无风险、误报，风险点)
            remark: "", //处理内容
            createUserId: this.$store.state.userInfo.userId, //处理用户id
            createUser: this.$store.state.userInfo.name, //处理用户名
            projectName: this.conditionObj.projectName, //项目名称
            projectIds: this.conditionObj.projectId, //项目id
            customName: this.conditionObj.customName, //客户名称
          })
            .then((res) => {
              //instance.confirmButtonLoading = false;
              this.$message.success("处理成功");
              this.$emit("changeList");
              // done();
            })
            .catch((err) => {
              //instance.confirmButtonLoading = false;
              this.$message.error("处理失败");
              //done();
            });
          // this.$msgbox({
          //   title: "",
          //   message: h("div", null, [
          //     h(
          //       "img",
          //       {
          //         style:
          //           "display: block;margin: 0 auto 10px;width:60px;height:60px;",
          //         attrs: {
          //           src: require("@/assets/images/newRiskControl/cl-gj-icon.png"),
          //           alt: "Alternative Text",
          //         },
          //       },
          //       null
          //     ),
          //     h(
          //       "div",
          //       {
          //         style:
          //           "font-weight: bold;font-size: 16px;color: #333333;text-align: center;margin-bottom: 24px;",
          //       },
          //       "提示 "
          //     ),
          //     h(
          //       "div",
          //       {
          //         style:
          //           "font-size: 14px;color: #333333;text-align: center;margin-bottom: 24px;",
          //       },
          //       "此告警是否误报？"
          //     ),
          //   ]),
          //   showCancelButton: true,
          //   confirmButtonText: "确定",
          //   cancelButtonText: "取消",
          //   beforeClose: (action, instance, done) => {
          //     if (action === "confirm") {
          //       instance.confirmButtonLoading = true;
          //       instance.confirmButtonText = "执行中...";
          //       riskHandle({
          //         vehicleId: this.equipmentData.vehicleId, //车辆id
          //         plate: this.equipmentData.plate, //车牌
          //         dataType: this.equipmentType, //数据类型(1:设备离线，2:设备故障，3:天气监控，4:工时监控，5:保单脱保，6：设备出险)
          //         interveneWay: "误报", //处理方式(确认无风险、误报，风险点)
          //         remark: "", //处理内容
          //         createUserId: this.$store.state.userInfo.userId, //处理用户id
          //         createUser: this.$store.state.userInfo.name, //处理用户名
          //         projectName: this.conditionObj.projectName, //项目名称
          //         projectIds: this.conditionObj.projectId, //项目id
          //         customName: this.conditionObj.customName, //客户名称
          //       })
          //         .then((res) => {
          //           instance.confirmButtonLoading = false;
          //           this.$message.success("处理成功");
          //           this.$emit("changeList");
          //           done();
          //         })
          //         .catch((err) => {
          //           instance.confirmButtonLoading = false;
          //           this.$message.error("处理失败");
          //           done();
          //         });
          //     } else {
          //       done();
          //     }
          //   },
          // }).then((action) => {});
          break;
        case "3":
          // this.isDispose = true;
          this.$emit(
            "changeDialog",
            "isDispose",
            this.equipmentData,
            this.equipmentType
          );
          break;
        case "4":
          // this.isDispose = true;
          this.$emit(
            "changeDialog",
            "isDispose",
            this.equipmentData,
            this.equipmentType
          );
          break;
      }
    },
    handleShowDetails() {
      console.log(this.equipmentData, this.equipmentType, this.modelKey);
      if (this.equipmentType == 7 && this.equipmentData.isCommercialVehicles) {
        this.$router.push({
          path: "/monitorDetails",
          query: {
            vehicleId: this.equipmentData.vehicleId,
            conditionObj: JSON.stringify(this.conditionObj),
          },
        });
        return false;
      } else if (this.equipmentType == 7) {
        this.$router.push({
          path: "/alarmMonitoring",
          query: {
            vehicleId: this.equipmentData.vehicleId,
            conditionObj: JSON.stringify(this.conditionObj),
          },
        });
        return false;
      }
      // this.isDetails = true;
      this.$emit(
        "changeDialog",
        "isDetails",
        this.equipmentData,
        this.equipmentType
      );
    },
  },
};
</script>
<style lang="scss" scoped>
.car-d {
  width: 348px;
  margin-left: 16px;
  margin-top: 10px;
  padding: 12px 10px 12px 12px;
  background-color: #fff;
  cursor: pointer;
  border-radius: 10px 10px 10px 10px;
  border: 1px solid #dee3e6;
}
.card-box {
  display: flex;
  &-img {
    ::v-deep img {
      width: 72px;
      height: 72px;
      margin-right: 10px;
      margin-top: 0;
      border-radius: 4px;
    }
  }
  &-content {
    flex: 1;
    overflow: hidden;
    &-title {
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-bottom: 6px;
      &-name {
        flex: 1;
        font-weight: bold;
        font-size: 16px;
        color: #333333;
      }
      &-status {
        height: 24px;
        line-height: 24px;
        font-size: 12px;
        color: #999999;
        padding: 0 8px;
        background: #eeeeee;
        border-radius: 2px 2px 2px 2px;
        border: 1px solid #e1e1e1;
      }
      &-status2 {
        color: #00bc0d;
        background: #e5f8e6;
        border: 1px solid #b7edba;
      }
    }
    &-special {
      height: 42px;
      display: flex;
      font-weight: 400;
      font-size: 14px;
      color: #666666;
      &-icon {
        width: 18px;
        height: 18px;
        margin-right: 5px;
        margin-top: 3px;
      }
      &-name {
        flex: 1;
        // white-space: nowrap; /* 确保文本在一行内显示，不换行 */
        text-overflow: ellipsis; /* 使用省略号表示文本溢出 */
        overflow: hidden;
        display: -webkit-box; //特别显示模式
        -webkit-line-clamp: 2; //行数
        line-clamp: 2;
        -webkit-box-orient: vertical; //盒子中内容竖直排列
        line-height: 20px;
      }
      &-alarm {
        height: 35px;
        line-height: 35px;
        font-weight: 400;
        font-size: 14px;
        color: #e50000;
        padding: 0 8px;
        background: #fff3f3;
        border-radius: 6px;
      }
      &-working {
        height: 35px;
        line-height: 35px;
        font-weight: 400;
        font-size: 14px;
        color: #1C80BF;
        padding: 0 8px;
        background: #EFF8FF;
        border-radius: 6px
      }
      &-alone {
        flex: 1;
        white-space: nowrap; /* 确保文本在一行内显示，不换行 */
        text-overflow: ellipsis; /* 使用省略号表示文本溢出 */
        overflow: hidden;
      }
    }
    &-weather {
      display: flex;
      align-items: center;
      height: 31px;
      line-height: 32px;
      font-size: 14px;
      color: #666666;
      font-weight: 400;
      padding: 0 16px;
      background: #fff8f2;
      border-radius: 6px;
      img {
        width: 18px;
        height: 18px;
        margin-right: 6px;
      }
    }
    &-operation {
      display: flex;
      align-items: center;
      justify-content: space-between;
      font-size: 14px;
      color: #333333;
      margin-top: 8px;
      .cl-button {
        background: #0080ff;
        border: 1px solid #0080ff;
        &-b {
          display: flex;
          align-items: center;
        }
        span {
          display: flex;
          align-items: center;
        }
        img {
          width: 20px;
          height: 20px;
          margin-right: 4px;
        }
      }
      .is-disabled {
        opacity: 0.5;
      }
    }
  }
}
.car-d:hover {
  background: #f8fbff;
  border: 1px solid #3198ff;
}
</style>
