<template>
  <div class="device-offline">
    <div class="device-offline-tab">
      <el-tabs v-model="activeName">
        <el-tab-pane label="最近位置" name="RtpMapDetails"></el-tab-pane>
        <el-tab-pane label="在线记录" name="manHour"></el-tab-pane>
      </el-tabs>
    </div>
    <div class="device-offline-content">
      <deviceLocation v-show="activeName == 'RtpMapDetails'" v-model="vehicle" :vehicleList="vehicleListOnMap" />
      <deviceOnlineRecording v-show="activeName == 'manHour'" v-model="vehicle" />
    </div>
  </div>
</template>

<script>
import deviceLocation from "@/views/riskControlManagement/components/deviceLocation";
import deviceOnlineRecording from "@/views/riskControlManagement/components/deviceOnlineRecording";
export default {
  components: { deviceLocation, deviceOnlineRecording },
  props: {
    vehicle: {
      type: Object,
      default: ()=> {}
    },
    vehicleListOnMap: {
      type: Array,
      default: ()=> []
    }
  },
  data() {
    return {
      activeName: "RtpMapDetails",
    };
  },
  methods: {
    handleTabClick(e) {
      console.log(e);
    },
    getList() {
      console.log('---');
    }
  },
};
</script>

<style lang="scss" scoped>
.device-offline {
  height: 100%;
  &-tab {
    ::v-deep .el-tabs {
      .el-tabs__item {
        font-size: 14px;
        color: #333333;
        padding: 0 20px;
      }
      .el-tabs__header {
        margin: 0;
      }
      .is-active {
        font-weight: bold;
        font-size: 14px;
        color: #0080ff;
      }
      .el-tabs__nav-wrap::after {
        height: 0;
      }
      .el-tabs__active-bar {
        background-color: #0080FF !important;
        display: block;
        height: 3px;
        border-radius: 2px;
      }
    }
  }
  &-content {
    height: calc(100% - 100px);
    .deviceLocation {
      border-bottom: 1px solid #DEE3E6;
    }
  }
}
</style>