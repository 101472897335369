<template>
  <div class="everyday" v-loading="isLoading">
    <div class="status-box">
      <div
        class="status-box-button"
        :class="{ 'status-box-button-current': complete == '' }"
        @click="complete = ''"
      >
       未完成({{ everydayGather.notHandleCount || "0" }})
      </div>
      <div
        class="status-box-button"
        :class="{ 'status-box-button-current': complete == 1 }"
        @click="complete = 1"
      >
        已完成({{ everydayGather.handleCount || "0" }})
      </div>
    </div>
    <div class="everyday-box">
      <shell
        v-for="(item, index) in moduelTypeList.filter(vv => !conditionObj.isScProject || vv.type != 8)"
        :key="index"
        :moduelData="item"
      >
        <div
          v-if="everydayGather[item.key] && everydayGather[item.key].length"
          style="
            display: flex;
            flex-wrap: wrap;
            padding-bottom: 16px;
            padding-right: 16px;
          "
        >
          <equipmentCard
            v-for="(equipmentItem, equipmentIndex) in everydayGather[
              item.key
            ] || []"
            :key="item.key + equipmentIndex"
            :equipmentData="equipmentItem"
            :isOperation="index < 2"
            :complete="complete"
            :modelKey="item.modelKey"
            :equipmentType="item.type"
            @changeDialog="handleChangeDialog"
            :conditionObj="conditionObj"
            @changeList="getList"
          />
        </div>
      </shell>
    </div>
    <disposeDialog
      v-model="isDispose"
      :equipmentData="equipmentData"
      :conditionObj="conditionObj"
      :equipmentType="disposeModelType"
      @changeList="getList"
    />
    <detailsDialog
      v-model="isDetails"
      :equipmentData="equipmentData"
      :complete="0"
      :conditionObj="conditionObj"
      :equipmentType="disposeModelType"
      @changeDialog="handleChangeDialog"
      @changeList="(isDispose = false), getList()"
    />
  </div>
</template>

<script>
import shell from "@/views/riskControlManagement/components/shell";
import equipmentCard from "@/views/riskControlManagement/components/equipmentCard";
import disposeDialog from "@/views/riskControlManagement/components/disposeDialog";
import detailsDialog from "@/views/riskControlManagement/components/detailsDialog";
import { dailyInspection } from "@/api/SystemData";
export default {
  name: "everyday",
  components: { shell, equipmentCard, disposeDialog, detailsDialog },
  props: {
    paramsData: {
      type: Object,
      default: () => {},
    },
    projectId: {
      type: [Number, String],
      default: null,
    },
    customerUserId: {
      type: [Number, String],
      default: null,
    },
    conditionObj: {
      type: Object,
      default: () => {},
    },
  },
  watch: {
    // projectId: {
    //   handler(v) {
    //     this.getList();
    //   }
    // },
    customerUserId: {
      handler(v) {
        clearTimeout(this.timeObj);
        this.timeObj = setTimeout(() => {
          if (!v) {
            this.everydayGather = {};
            return false;
          }
          this.getList();
          clearTimeout(this.timeObj);
        },100);
      },
    },
    complete: {
      handler(v) {
        this.getList();
      },
    },
  },
  data() {
    return {
      moduelTypeList: [
        {
          bgColor:
            "linear-gradient( 270deg, rgba(255,255,255,0) 0%, rgba(102,102,102,0.45) 100%)",
          title: "设备离线",
          icon: require("@/assets/images/newRiskControl/sblx.png"),
          tooltip: "展示昨日没有上线过的设备",
          modelKey: "address",
          type: "1",
          key: "deviceOfflineList",
        },
        {
          bgColor:
            "linear-gradient( 270deg, rgba(255,255,255,0) 0%, rgba(255,174,174,0.45) 100%)",
          title: "设备故障",
          icon: require("@/assets/images/newRiskControl/sbgz.png"),
          tooltip: "展示当前在线且有故障的设备",
          modelKey: "alarm",
          type: "2",
          key: "deviceFaultList",
        },
        {
          bgColor:
            "linear-gradient( 270deg, rgba(255,255,255,0) 0%, rgba(255,212,174,0.45) 100%)",
          title: "天气监控",
          icon: require("@/assets/images/newRiskControl/tqjk.png"),
          tooltip:
            "根据设备当前所在的位置获取天气信息，展示当天有恶劣天气的设备",
          modelKey: "weather",
          type: "3",
          key: "weatherMonitoringList",
        },
        {
          bgColor:
            "linear-gradient( 270deg, rgba(255,255,255,0) 0%, rgba(255,174,239,0.45) 100%)",
          title: "视频监控",
          icon: require("@/assets/images/newRiskControl/spjk.png"),
          tooltip: "展示当前在线且可以查看视频的设备",
          modelKey: "address",
          type: "8",
          key: "ovpList",
        },
        {
          bgColor:
            "linear-gradient( 270deg, rgba(255,255,255,0) 0%, rgba(174,223,255,0.45) 100%)",
          title: "工时监控",
          icon: require("@/assets/images/newRiskControl/gsjk.png"),
          tooltip: "展示昨日连续开工超3小时、累计开工超8小时的车辆/设备",
          modelKey: "working",
          type: "4",
          key: "workingList",
        },
        {
          bgColor:
            "linear-gradient( 270deg, rgba(255,255,255,0) 0%, rgba(255,236,174,0.45) 100%)",
          title: "保单脱保",
          icon: require("@/assets/images/newRiskControl/bdtb.png"),
          tooltip: "展示当天保单脱保的设备，即是设备名下保单全部处于已过期状态",
          modelKey: "address",
          type: "5",
          key: "policyReleaseList",
        },
        {
          bgColor:
            "linear-gradient( 270deg, rgba(255,255,255,0) 0%, rgba(174,247,255,0.45) 100%)",
          title: "设备出险",
          icon: require("@/assets/images/newRiskControl/sbcx.png"),
          tooltip: "展示当天有新出险案件的设备",
          modelKey: "address",
          type: "6",
          key: "deviceDangerList",
        },
      ],
      complete: "",
      isLoading: false,
      everydayGather: {},
      isDispose: false,
      isDetails: false,
      equipmentData: {},
      disposeModelType: "",
      timeObj: null,
    };
  },
  methods: {
    async getList() {
      try {
        this.isLoading = true;
        const loading = this.$loading({
          lock: true,
          text: '加载中...',
          spinner: 'el-icon-loading',
          background: 'rgba(0, 0, 0, 0.7)'
        });
        const { data } = await dailyInspection({
          projectId: this.projectId,
          vehicleGroupId: this.customerUserId,
          complete: !this.complete ? null : this.complete,
        });
        loading.close();
        this.isLoading = false;
        if (data) {
          console.log(data);
          this.everydayGather = data;
        } else {
          this.everydayGather = {};
        };
        this.$forceUpdate();
      } catch (error) {
        this.everydayGather = {};
        this.isLoading = false;
      }
    },
    handleChangeDialog(type, data, modelType) {
      this[type] = true;
      this.equipmentData = data;
      this.disposeModelType = modelType;
    },
  },
};
</script>

<style lang="scss" scoped>
.everyday {
  height: 100%;
  display: flex;
  flex-direction: column;
  &-box {
    flex: 1;
    overflow: auto;
  }
  .status-box {
    display: flex;
    &-button {
      display: flex;
      align-items: center;
      height: 35px;
      font-size: 14px;
      color: #333333;
      padding: 0 15px;
      margin-left: 14px;
      background: #ffffff;
      border-radius: 18px 18px 18px 18px;
      border: 1px solid #dee3e6;
      cursor: pointer;
      &-current {
        color: #0080ff;
        background: #e5f2ff;
        border: 1px solid #0080ff;
      }
    }
  }
}
</style>
