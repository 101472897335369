<template>
  <div class="risk-control-box" v-loading="projectLoading || projectUserLoading">
    <div class="project" v-loading="projectLoading">
      <div
        class="project-item text-ellipsis"
        :class="{ 'project-item-current': projectName == item.projectName }"
        @click="
          (projectName = item.projectName),
            (projectId = item.projectId),
            getProjectUser(item)
        "
        v-for="(item, index) in projectList"
        :key="index"
      >
        {{ item.projectName }}
      </div>
      <el-button
              class="polecy-button"
              @click.native.prevent="handleGoDetail"
              type="primary"
          v-if="hasPerms('newFKGL-report')"
              size="small"
              >风控报告</el-button
            >
    </div>
    <div class="client">
      <el-input
        placeholder="搜索"
        prefix-icon="el-icon-search"
        v-model="input2"
      >
      </el-input>
      <div class="client-content">
        <div
          class="client-content-item"
          :class="{ 'client-content-item-current': isCurrent == index }"
          @click="isCurrent == index ? isCurrent = null : isCurrent = index"
          v-for="(item, index) in userList"
          :key="index"
        >
          <div class="client-content-item-label text-ellipsis">
            {{ item.customerName }}
          </div>
          <div class="client-content-item-value">({{ item.deviceCount }})</div>
        </div>
      </div>
    </div>
    <div class="tabs">
      <div class="tabs-screening">
        <!-- <div
            class="tabs-screening-identical"
          >
            
            <div class="el-date-b">
              <div class="tabs-screening-identical-botton" @click="handleNextDate(-1)">
                <i class="el-icon-arrow-left"></i>
              </div>
              <el-date-picker
                v-model="bodyData.dateTime"
                type="date"
                value-format="yyyy-MM-dd"
                placeholder="选择日期"
              >
              </el-date-picker>
              <div
                class="tabs-screening-identical-botton"
                :class="{
                  'tabs-screening-identical-botton-d': bodyData.dateTime == endDateTime,
                }"
                @click="handleNextDate(1)"
              >
                <i class="el-icon-arrow-right"></i>
              </div>
            </div>
          </div> -->
        <div
          class="tabs-screening-button"
          @click="handleGoPath"
          v-if="hasPerms('newFKGL-record')"
        >
          <img src="@/assets/images/newRiskControl/gyjl.png" alt="" srcset="" />
          巡检记录
        </div>
      </div>
      <el-tabs v-model="activeName" @tab-click="handleClick">
        <el-tab-pane label="日常巡检" name="everyday">
          <everyday
            :projectId="userList[isCurrent] ? userList[isCurrent].projectId : projectId"
            :conditionObj="conditionObj"
            :customerUserId="
              userList[isCurrent] ? userList[isCurrent].projectId : projectId
            "
          />
        </el-tab-pane>
        <el-tab-pane label="运行数据监控" name="monitor">
          <monitor
            :projectId="userList[isCurrent] ? userList[isCurrent].projectId : projectId"
            :conditionObj="conditionObj"
            :customerUserId="
              userList[isCurrent] ? userList[isCurrent].projectId : projectId
            "
          />
        </el-tab-pane>
        <el-tab-pane label="跟车管理" name="carFollowing">
          <carFollowing
            :projectId="userList[isCurrent] ? userList[isCurrent].projectId : projectId"
            :conditionObj="conditionObj"
            :customerUserId="
              userList[isCurrent] ? userList[isCurrent].projectId : projectId
            "
          />
        </el-tab-pane>
      </el-tabs>
    </div>
  </div>
</template>

<script>
import dayjs from "dayjs";
import everyday from "@/views/riskControlManagement/components/everyday";
import monitor from "@/views/riskControlManagement/components/monitor";
import carFollowing from "@/views/riskControlManagement/components/carFollowing";
import { userProject, projectCustomer, getRickVehicleGroup } from "@/api/SystemData";
import { hasPerms } from "@/utils/auth";
export default {
  name: "riskControlManagement",
  data() {
    return {
      isCurrent: null,
      input2: "",
      activeName: "everyday",
      bodyData: {
        dateTime: dayjs().format("YYYY-MM-DD"),
      },
      endDateTime: dayjs().format("YYYY-MM-DD"),
      projectList: [],
      projectName: "",
      projectId: "",
      projectLoading: false,
      projectUserLoading: false,
      projectUserList: [],
      conditionObj: {
        projectId: "",
        projectName: "",
        customName: "",
        customId: "",
        isScProject: "",
      },
      hasPerms,
    };
  },
  components: { everyday, monitor, carFollowing },
  computed: {
    userList() {
      if (!this.projectUserList || !this.projectUserList.length) {
        return [];
      }
      return this.input2
        ? this.projectUserList.filter(
            (val) => val.customerName.indexOf(this.input2) > -1
          )
        : this.projectUserList;
    },
  },
  watch: {
    isCurrent: {
      handler(v) {
        if (v === null) {
           this.conditionObj.customName = '';
           this.conditionObj.customId = '';
          return false;
        }
        this.conditionObj.customName =
          this.userList[this.isCurrent].customerName;
        this.conditionObj.customId =
          this.userList[this.isCurrent].customerName;
      },
      deep: true,
    },
  },
  async mounted() {
    try {
      this.projectLoading = true;
      this.projectUserLoading = true;
      let res = await userProject({
        userId: this.$store.state.userInfo.MJUserId,
      });
      this.projectLoading = false;
      if (res && res.data && res.data.length > 0) {
        this.projectList = res.data;
        this.projectName = res.data[0].projectName;
        this.projectId = res.data[0].projectId;
        this.getProjectUser(res.data[0]);
      } else {
        this.projectUserLoading = false;
      }
    } catch (error) {
      this.projectLoading = false;
      this.projectUserLoading = false;
    }
  },
  methods: {
    handleClick(e) {
      // console.log(e);
    },
    handleNextDate(e) {
      if (e > 0 && this.bodyData.dateTime == this.endDateTime) return false;
      this.bodyData.dateTime = dayjs(this.bodyData.dateTime)
        .add(e, "day")
        .format("YYYY-MM-DD");
    },
    async getProjectUser(e) {
      try {
        this.projectUserLoading = true;
        this.conditionObj.projectId = this.projectId;
        this.conditionObj.projectName = this.projectName;
        this.conditionObj.isScProject = e.projectType == '商车' ? true : false;

        // let res = await projectCustomer(this.projectId);
        let res = await projectCustomer({projectId: this.projectId});
        this.projectUserLoading = false;
        if (res && res.data && res.data.length > 0) {
          this.isCurrent = 0;
          this.projectUserList = res.data;
        } else {
          this.projectUserList = [];
          this.isCurrent = null;
        }
      } catch (error) {
        this.isCurrent = null;
        this.projectUserLoading = false;
      }
    },
    handleGoPath() {
      this.$router.push({
        path: "/interventionRecord",
      });
    },
    handleGoDetail() {
      this.$router.push({
        path: "/riskControlReport",
      });
    }
  },
};
</script>

<style lang="scss" scoped>
.risk-control-box {
  height: 100%;
  display: flex;
  background: #f6f8f9;
  .project {
    position: relative;
    height: 100%;
    width: 128px;
    background: #ffffff;
    box-shadow: 5px 0px 6px 1px rgba(102, 102, 102, 0.07);
    &-item {
      height: 58px;
      line-height: 58px;
      width: 128px;
      font-size: 14px;
      color: #333333;
      padding: 0 20px;
      cursor: pointer;
      &-current {
        position: relative;
        color: #0080ff;
        background: #e5f2ff;
      }
      &-current::before {
        content: "";
        position: absolute;
        left: 0;
        top: 0;
        width: 6px;
        height: 58px;
        background: #0080ff;
      }
    }
    .polecy-button {
      position: absolute;
      bottom: 20px;
      left: 50%;
      transform: translateX(-50%);
    }
  }
  .client {
    width: 248px;
    height: 100%;
    display: flex;
    flex-direction: column;
    margin-left: 6px;
    // margin-right: 10px;
    padding: 0 12px;
    background: #ffffff;
    ::v-deep .el-input {
      height: 58px;
      line-height: 58px;
    }
    &-content {
      flex: 1;
      overflow: auto;
      &-item {
        display: flex;
        height: 58px;
        line-height: 58px;
        color: #333333;
        cursor: pointer;
        &-label {
          flex: 1;
          font-size: 14px;
        }
        &-value {
          font-size: 14px;
        }
        &-current {
          color: #0080ff;
        }
      }
    }
  }
  .tabs {
    position: relative;
    // flex: 1;
    width: calc(100% - 366px);
    border-left: 1px solid #dee3e6;
    ::v-deep .el-tabs {
      height: 100%;
      .el-tabs__header {
        height: 50px;
        line-height: 50px;
        // padding: 0 4px;
        margin-bottom: 0;
        background-color: #fff;
      }
      .el-tabs__content {
        height: calc(100% - 50px);
        padding: 12px 10px;
      }
      .el-tabs__item {
        font-weight: 400;
        font-size: 14px;
        color: #333333;
        padding: 0 20px;
      }
      .is-active {
        font-weight: bold;
        font-size: 14px;
        color: #0080ff;
      }
      .el-tabs__nav-wrap::after {
        height: 1px;
        background-color: #dee3e6;
      }
      .el-tabs__active-bar {
        background-color: transparent;
        height: 4px;
        display: flex;
        justify-content: center;
      }
      .el-tabs__active-bar::before {
        content: "";
        display: block;
        width: 30px !important;
        height: 4px;
        background-color: #0080ff;
        border-radius: 2px;
      }
      .el-tab-pane {
        height: 100%;
      }
    }
    &-screening {
      position: absolute;
      height: 50px;
      top: 0;
      right: 0;
      padding-right: 16px;
      display: flex;
      align-items: center;
      z-index: 1;
      &-identical {
        display: flex;
        align-items: center;
        margin-right: 16px;
        ::v-deep .el-select {
          margin-left: 8px;
        }
        ::v-deep .el-date-editor {
          width: 169px;
          margin: 0 8px;
        }
        .el-date-b {
          display: flex;
          align-items: center;
          margin-left: 8px;
        }

        &-botton {
          display: flex;
          align-items: center;
          justify-content: center;
          width: 32px;
          height: 32px;
          cursor: pointer;
          background: #f2f7fd;
          border-radius: 4px 4px 4px 4px;
          border: 1px solid #dee3e6;
          &-d {
            cursor: no-drop;
            opacity: 0.8;
          }
        }
        &-botton2 {
          display: flex;
          align-items: center;
          justify-content: center;
          height: 32px;
          padding: 0 8px;
          cursor: pointer;
          font-size: 12px;
          color: #333333;
          margin-right: 16px;
          background: #f2f7fd;
          border-radius: 4px 4px 4px 4px;
          border: 1px solid #dee3e6;
          &-d {
            cursor: no-drop;
            opacity: 0.8;
          }
          img {
            width: 16px;
            height: 16px;
            margin-right: 4px;
            margin-top: -2px;
          }
        }
      }
      &-button {
        display: flex;
        align-items: center;
        height: 32px;
        font-size: 14px;
        color: #0080ff;
        cursor: pointer;
        padding: 0 16px;
        background: #e5f2ff;
        border-radius: 18px;
        img {
          width: 20px;
          height: 20px;
          margin-right: 4px;
          margin-left: 4px;
        }
      }
    }
  }
  .status-box {
    display: flex;
    &-button {
      display: flex;
      align-items: center;
      height: 35px;
      font-size: 14px;
      color: #333333;
      padding: 0 15px;
      margin-left: 14px;
      background: #ffffff;
      border-radius: 18px 18px 18px 18px;
      border: 1px solid #dee3e6;
      cursor: pointer;
      &-current {
        color: #0080ff;
        background: #e5f2ff;
        border: 1px solid #0080ff;
      }
    }
  }
}
</style>
