<template>
  <div class="shell-box">
    <el-collapse v-model="isShow">
      <el-collapse-item name="1">
        <template slot="title">
          <div
            class="shell-box-title"
            :style="{ background: moduelData.bgColor }"
          >
            <div class="shell-box-title-left">
              <img
                :src="moduelData.icon"
                alt=""
                class="shell-box-title-icon"
                srcset=""
              />
              <div class="shell-box-title-name">{{ moduelData.title }}</div>
              <el-tooltip
                class="item"
                effect="dark"
                :content="moduelData.tooltip"
                placement="bottom"
              >
                <img
                  src="@/assets/images/newRiskControl/exclamation-circle-Regular.png"
                  alt=""
                  class="shell-box-title-icon2"
                  srcset=""
                />
              </el-tooltip>
            </div>
          </div>
        </template>
        <slot>
          <div class="vacancy">
            <img
              class="vacancy-icon"
              src="../../../assets/images/empty-20240628.png"
              alt=""
              srcset=""
            />
            <div class="vacancy-text">{{ vacancyPlaceholder }}</div>
          </div>
        </slot>
      </el-collapse-item>
    </el-collapse>
  </div>
</template>

<script>
export default {
  props: {
    moduelData: {
      type: Object,
      default: () => {},
    },
    vacancyPlaceholder: {
      type: String,
      default: '暂无风险设备'
    }
  },
  data() {
    return {
      isShow: ["1"],
    };
  },
};
</script>

<style lang="scss" scoped>
.shell-box {
  margin-top: 10px;
  background: #ffffff;
  border-radius: 10px;
  &-title {
    width: 50%;
    display: flex;
    align-items: center;
    padding-left: 16px;
    border-radius: 10px 0px 0px 0px;
    &-left {
      display: flex;
      align-items: center;
    }
    &-icon {
      width: 22px;
      height: 22px;
    }
    &-icon2 {
      width: 20px;
      height: 20px;
    }
    &-name {
      font-weight: bold;
      font-size: 18px;
      color: #333333;
      margin-left: 8px;
      margin-right: 6px;
    }
  }
  ::v-deep .el-collapse {
    border: none !important;
    .el-collapse-item__header {
      height: 52px;
      line-height: 52px;
      border: none;
      border-radius: 10px 0px 0px 0px;
      .is-active::before {
        color: #303133;
        font-weight: 400;
      }
    }
    .el-collapse-item__content {
      padding: 0;
    }
    .el-collapse-item__wrap {
      border: none;
    }
  }
}
.vacancy {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: calc(100% - 50px);
  background-color: #fff;
  margin: 16px 0;
  &-icon {
    display: block;
    margin: auto;
    width: 99px;
    height: 76px;
  }
  &-text {
    text-align: center;
    font-size: 15px;
    color: #333333;
    margin-top: 5px;
  }
}
</style>
