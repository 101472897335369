<template>
  <el-dialog
    title="详情"
    :visible.sync="dialogVisible"
    custom-class="everyday-details-dialog"
    :width="(equipmentType ? widthList[equipmentType] : '868') + 'px'"
    :before-close="handleClose"
  >
    <div class="details-box" v-loading="mapLoading">
      <div class="details-box-title">
        <Iconfont
          :size="96"
          :name="vehicle.imageUrl"
        ></Iconfont>
<!--      `https://yiligps.oss-cn-shenzhen.aliyuncs.com/ui-show/${
            carImgList.includes(
              vehicle.machineryProductType + vehicle.machineryEquipmentType
            )
              ? vehicle.machineryProductType + vehicle.machineryEquipmentType
              : carImgList.includes(vehicle.machineryProductType)
              ? vehicle.machineryProductType
              : 'excavating_machinery'
          }.png` -->
        <div class="details-box-title-content">
          <!-- <div
            class="details-box-title-content-status"
            v-if="vehicle.isCommercialVehicles"
            :class="{
              'details-box-title-content-status1': [2, 4].includes(vehicle.Z),
            }"
          >
            AEB-{{ [2, 4].includes(vehicle.Z) ? "在线" : "离线" }}
          </div> v-else -->
          <div style="display: flex; gap: 8px">
            <div
              v-for="(item, index) in vehicle.terminalInfos || []"
              :key="index"
              :class="[
                'details-box-title-content-status',
                'details-box-title-content-status' + (item.isOnline ? '1' : ''),
              ]"
            >
              {{ vehicle.isCommercialVehicles ? 'AEB' : terminalName[item.deviceType] }}-{{
                item.isOnline ? "在线" : "离线"
              }}
            </div>
          </div>
          <div class="details-box-title-content-zx">
            <div>{{ vehicle.P || "--" }}</div>
            <div
              class="details-box-title-content-zx-status"
              :class="['details-box-title-content-zx-status' + vehicle.Z]"
            >
              持续{{ getStateLabel() }}
              <span>{{ vehicle.Dm ? getDwellTime5(vehicle.Dm * 1000) : "--" }}</span
              >
              <!-- equipmentData.isOnline
              ? "持续在线"
              : `离线(${getDwellTime4(equipmentData.lastDeviceTime) || "--"})` }} -->
            </div>
          </div>
          <div class="details-box-title-content-type">
            {{ vehicle.machineryProductTypeStr }} {{ vehicle.frameNo }}
          </div>
        </div>
      </div>
      <!-- {{ equipmentType }} -->
      <!-- <deviceOffline :vehicle="vehicle" :vehicleListOnMap="vehicleListOnMap" /> -->
      <!-- <deviceFault :vehicle="vehicle" /> -->
      <div style="height: 426px; overflow: hidden">
        <components
          :is="riskTypeList[equipmentType]"
          v-if="equipmentType"
          ref="detailsTab"
          v-model="vehicle"
          :vehicle="vehicle"
          :vehicleListOnMap="vehicleListOnMap"
        ></components>
      </div>
    </div>
    <span slot="footer" class="dialog-footer">
      <el-dropdown
        @command="handleCommand"
        :disabled="complete || countdown > 0 ? true : false"
      >
        <el-button
          :disabled="complete || countdown > 0 ? true : false"
          class="cl-button"
          type="primary"
          size="mini"
          >处理 <span v-if="countdown > 0">({{ countdown }}s)</span></el-button
        >
        <el-dropdown-menu slot="dropdown">
          <el-dropdown-item command="1" @click.stop=""
            >确认无风险</el-dropdown-item
          >
          <el-dropdown-item command="2" @click.stop="">误报</el-dropdown-item>
          <el-dropdown-item command="3" @click.stop="">风险点</el-dropdown-item>
          <!-- <el-dropdown-item command="4">电话联系</el-dropdown-item> -->
        </el-dropdown-menu>
      </el-dropdown>
    </span>
  </el-dialog>
</template>

<script>
import {
  getBrandInfoPageList,
  zeroPartsAddOrUpdate,
  getZeroPartsDetails,
} from "@/api/basicData";
import deviceOffline from "@/views/riskControlManagement/components/deviceOffline";
import working from "@/views/riskControlManagement/components/working";
import deviceFault from "@/views/riskControlManagement/components/deviceFault";
import policyRelease from "@/views/riskControlManagement/components/policyRelease";
import deviceDanger from "@/views/riskControlManagement/components/deviceDanger";
import weatherMonitoring from "@/views/riskControlManagement/components/weatherMonitoring";
import videoInspection from "@/views/riskControlManagement/components/videoInspection";
import { getDeviceInfo } from "@/api/live-monitor-api";
import { getDwellTime5, getDwellTime3 } from "@/utils/formatDate";
import { riskHandle } from "@/api/SystemData";
export default {
  components: {
    deviceOffline,
    working,
    deviceFault,
    weatherMonitoring,
    policyRelease,
    deviceDanger,
    videoInspection,
  },
  props: {
    showDialog: Boolean,
    equipmentType: {
      type: [String, Number],
      default: "",
    },
    equipmentData: {
      type: Object,
      default: () => {},
    },
    conditionObj: {
      type: Object,
      default: () => {},
    },
    complete: {
      type: [String, Number],
      default: "",
    },
  },
  model: {
    prop: "showDialog",
    event: "change",
  },
  watch: {
    dialogVisible: {
      handler(v) {
        if (!v) {
          this.$emit("change", false);
        } else {
          this.$emit("change", true);
        }
      },
    },
    showDialog: {
      handler(v) {
        if (v) {
          this.dialogVisible = true;
          this.$nextTick(() => {
            this.init();
          });
        } else {
          this.dialogVisible = false;
          clearInterval(this.setTimeObj);
          this.countdown = 0;
        }
      },
    },
  },
  data() {
    return {
      dialogVisible: false,
      ruleForm: {
        partName: "",
      },
      terminalName: ["", "OVP", "OBD", "有线GPS", "无线GPS", "PFT", "BSD", 'AEB'],
      carImgList: [
        "aerial_work_machinery1",
        "aerial_work_machinery2",
        "compaction_machinery",
        "excavating_machinery",
        "excavating_machinery1",
        "hoisting_machinery",
        "hoisting_machinery3",
        "mining_machinery",
        "piling_machinery1",
        "shovel_transport_machinery0",
        "shovel_transport_machinery1",
        "shovel_transport_machinery3",
        "industrial_vehicles1",
        "agriculture_mechanics1",
        "concrete_machinery3",
        "shovel_transport_machinery9",
        "diao_guan_ji0",
        "yi_dong_dian_zhan0",
        "han_jie_gong_zuo_zhan0",
        "zhong_pin_jia_re_che0",
          'new_energy_vehicles0',
      ],
      vehicleListOnMap: [],
      vehicle: {},
      mapLoading: false,
      riskTypeList: [
        "",
        "deviceOffline",
        "deviceFault",
        "weatherMonitoring",
        "working",
        "policyRelease",
        "deviceDanger",
        "videoInspection",
        "videoInspection",
      ],
      // videoInspection
      widthList: ["", "868", "868", "602", "1090", "868", "1090"],
      setTimeObj: null,
      countdown: 0,
      getDwellTime5,
    };
  },
  methods: {
    async init() {
      try {
        if (this.equipmentType == 8) {
          this.countdown = 15;
          clearInterval(this.setTimeObj);
          this.setTimeObj = setInterval(() => {
            this.countdown -= 1;
            if (this.countdown == 0) {
              clearInterval(this.setTimeObj);
            }
          }, 1000);
        }
        this.mapLoading = true;
        let res = await getDeviceInfo({
          vehicleId: this.equipmentData.vehicleId,
        });
        console.log(this.equipmentData, res.obj.data);
        this.mapLoading = false;
        if (res.flag == 1) {
          this.vehicleListOnMap = res.obj.data;
          this.$nextTick(() => {
            this.vehicle = res.obj.data[0] || {};
            this.$nextTick(() => {
              this.$refs.detailsTab.getList
                ? this.$refs.detailsTab.getList("reset", true)
                : "";
            });
            this.$forceUpdate();
          });
        } else {
          this.vehicle = {};
          this.vehicleListOnMap = [];
        }
      } catch (error) {
        console.log(error);
        this.mapLoading = false;
        this.vehicle = {};
        this.vehicleListOnMap = [];
      }
    },
    handleClose(done) {
      this.$refs.detailsTab.onCloseAll
        ? this.$refs.detailsTab.onCloseAll()
        : "";
      // this.$confirm("确认关闭？")
      //   .then((_) => {
      done();
      //   })
      //   .catch((_) => {});
    },
    handleSubmit() {
      this.$refs["ruleForm"].validate((valid) => {
        if (valid) {
          console.log("处理了");
          this.dialogVisible = false;
          // this.ruleForm.suppliersIds = this.suppliersIds;
          // this.ruleForm.userName = this.$store.state.userInfo.name;
          // this.ruleForm.userId = this.$store.state.userInfo.userId;
          // zeroPartsAddOrUpdate(this.ruleForm).then(res=>{
          //   this.$message.success('操作成功');
          //   this.dialogVisible = false;
          //   this.$emit('changeList')
          // })
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },
    handleReset() {
      this.suppliersIds = [];
      if (this.editId) {
        getZeroPartsDetails({
          id: this.editId,
        }).then((res) => {
          this.ruleForm = res.data;
          this.ruleForm.suppliersList = this.ruleForm.supplierList;
        });
      } else {
        this.ruleForm = {
          partName: "",
        };
      }
    },
    selectMachineryProductType(e) {
      console.log(e);
    },
    handleCommand(e) {
      const h = this.$createElement;
      const that = this;
      switch (e) {
        case "1":
          riskHandle({
            vehicleId: that.equipmentData.vehicleId, //车辆id
            plate: that.equipmentData.plate, //车牌
            dataType: that.equipmentType, //数据类型(1:设备离线，2:设备故障，3:天气监控，4:工时监控，5:保单脱保，6：设备出险)
            interveneWay: "确认无风险", //处理方式(确认无风险、误报，风险点)
            remark: "", //处理内容
            createUserId: that.$store.state.userInfo.userId, //处理用户id
            createUser: that.$store.state.userInfo.name, //处理用户名
            projectName: that.conditionObj.projectName, //项目名称
            projectIds: that.conditionObj.projectId, //项目id
            customName: that.conditionObj.customName, //客户名称
          })
            .then((res) => {
              //instance.confirmButtonLoading = false;
              this.$message.success("处理成功");
              this.dialogVisible = false;
              this.$emit("changeList");
              //done();
            })
            .catch((err) => {
              //instance.confirmButtonLoading = false;
              this.$message.success("处理失败");
              //done();
            });
          // this.$msgbox({
          //   title: "",
          //   message: h("div", null, [
          //     h(
          //       "img",
          //       {
          //         style:
          //           "display: block;margin: 0 auto 10px;width:60px;height:60px;",
          //         attrs: {
          //           src: require("@/assets/images/newRiskControl/cl-gj-icon.png"),
          //           alt: "Alternative Text",
          //         },
          //       },
          //       null
          //     ),
          //     h(
          //       "div",
          //       {
          //         style:
          //           "font-weight: bold;font-size: 16px;color: #333333;text-align: center;margin-bottom: 24px;",
          //       },
          //       "提示 "
          //     ),
          //     h(
          //       "div",
          //       {
          //         style:
          //           "font-size: 14px;color: #333333;text-align: center;margin-bottom: 24px;",
          //       },
          //       "此警告确认无风险？"
          //     ),
          //   ]),
          //   showCancelButton: true,
          //   confirmButtonText: "确定",
          //   cancelButtonText: "取消",
          //   beforeClose: (action, instance, done) => {
          //     if (action === "confirm") {
          //       instance.confirmButtonLoading = true;
          //       instance.confirmButtonText = "执行中...";
          //       riskHandle({
          //         vehicleId: that.equipmentData.vehicleId, //车辆id
          //         plate: that.equipmentData.plate, //车牌
          //         dataType: that.equipmentType, //数据类型(1:设备离线，2:设备故障，3:天气监控，4:工时监控，5:保单脱保，6：设备出险)
          //         interveneWay: "确认无风险", //处理方式(确认无风险、误报，风险点)
          //         remark: "", //处理内容
          //         createUserId: that.$store.state.userInfo.userId, //处理用户id
          //         createUser: that.$store.state.userInfo.name, //处理用户名
          //         projectName: that.conditionObj.projectName, //项目名称
          //         projectIds: that.conditionObj.projectId, //项目id
          //         customName: that.conditionObj.customName, //客户名称
          //       })
          //         .then((res) => {
          //           instance.confirmButtonLoading = false;
          //           this.$message.success("处理成功");
          //           this.dialogVisible = false;
          //           this.$emit("changeList");
          //           done();
          //         })
          //         .catch((err) => {
          //           instance.confirmButtonLoading = false;
          //           this.$message.success("处理失败");
          //           done();
          //         });
          //       // setTimeout(() => {
          //       //   done();
          //       //   setTimeout(() => {
          //       //     instance.confirmButtonLoading = false;
          //       //   }, 300);
          //       // }, 3000);
          //     } else {
          //       done();
          //     }
          //   },
          // }).then((action) => {});
          break;
        case "2":
          riskHandle({
            vehicleId: this.equipmentData.vehicleId, //车辆id
            plate: this.equipmentData.plate, //车牌
            dataType: this.equipmentType, //数据类型(1:设备离线，2:设备故障，3:天气监控，4:工时监控，5:保单脱保，6：设备出险)
            interveneWay: "误报", //处理方式(确认无风险、误报，风险点)
            remark: "", //处理内容
            createUserId: this.$store.state.userInfo.userId, //处理用户id
            createUser: this.$store.state.userInfo.name, //处理用户名
            projectName: this.conditionObj.projectName, //项目名称
            projectIds: this.conditionObj.projectId, //项目id
            customName: this.conditionObj.customName, //客户名称
          })
            .then((res) => {
              //instance.confirmButtonLoading = false;
              this.$message.success("处理成功");
              this.dialogVisible = false;
              this.$emit("changeList");
              //done();
            })
            .catch((err) => {
              //instance.confirmButtonLoading = false;
              this.$message.error("处理失败");
              //done();
            });
          // this.$msgbox({
          //   title: "",
          //   message: h("div", null, [
          //     h(
          //       "img",
          //       {
          //         style:
          //           "display: block;margin: 0 auto 10px;width:60px;height:60px;",
          //         attrs: {
          //           src: require("@/assets/images/newRiskControl/cl-gj-icon.png"),
          //           alt: "Alternative Text",
          //         },
          //       },
          //       null
          //     ),
          //     h(
          //       "div",
          //       {
          //         style:
          //           "font-weight: bold;font-size: 16px;color: #333333;text-align: center;margin-bottom: 24px;",
          //       },
          //       "提示 "
          //     ),
          //     h(
          //       "div",
          //       {
          //         style:
          //           "font-size: 14px;color: #333333;text-align: center;margin-bottom: 24px;",
          //       },
          //       "此告警是否误报？"
          //     ),
          //   ]),
          //   showCancelButton: true,
          //   confirmButtonText: "确定",
          //   cancelButtonText: "取消",
          //   beforeClose: (action, instance, done) => {
          //     if (action === "confirm") {
          //       instance.confirmButtonLoading = true;
          //       instance.confirmButtonText = "执行中...";
          //       riskHandle({
          //         vehicleId: this.equipmentData.vehicleId, //车辆id
          //         plate: this.equipmentData.plate, //车牌
          //         dataType: this.equipmentType, //数据类型(1:设备离线，2:设备故障，3:天气监控，4:工时监控，5:保单脱保，6：设备出险)
          //         interveneWay: "误报", //处理方式(确认无风险、误报，风险点)
          //         remark: "", //处理内容
          //         createUserId: this.$store.state.userInfo.userId, //处理用户id
          //         createUser: this.$store.state.userInfo.name, //处理用户名
          //         projectName: this.conditionObj.projectName, //项目名称
          //         projectIds: this.conditionObj.projectId, //项目id
          //         customName: this.conditionObj.customName, //客户名称
          //       })
          //         .then((res) => {
          //           instance.confirmButtonLoading = false;
          //           this.$message.success("处理成功");
          //           this.dialogVisible = false;
          //           this.$emit("changeList");
          //           done();
          //         })
          //         .catch((err) => {
          //           instance.confirmButtonLoading = false;
          //           this.$message.error("处理失败");
          //           done();
          //         });
          //     } else {
          //       done();
          //     }
          //   },
          // }).then((action) => {});
          break;
        case "3":
          // this.isDispose = true;
          this.$emit(
            "changeDialog",
            "isDispose",
            this.equipmentData,
            this.equipmentType
          );
          break;
        case "4":
          // this.isDispose = true;
          this.$emit(
            "changeDialog",
            "isDispose",
            this.equipmentData,
            this.equipmentType
          );
          break;
      }
    },
    getStateLabel() {
      const { vehicle } = this;
      if (vehicle) {
        // if (vehicle.Z === 2 && vehicle.isCommercialVehicles) return "开工";
        // if (vehicle.Z === 4 && vehicle.isCommercialVehicles) return "停工";
        // if (vehicle.Z === 0) return "怠速";
        // if (vehicle.Z === 1) return "行驶";
        // if (vehicle.Z === 2) return "作业";
        // if (vehicle.Z === 3) return "通电";
        // if (vehicle.Z === 4) return "熄火";
        // if (vehicle.Z === 5) return "离线";
        if (vehicle.Z === 0) return "停工";
        if (vehicle.Z === 1) return "开工";
        if (vehicle.Z === 5) return "离线";
        return "从未上线";
      }
      return "从未上线";
    },
    getDwellTime(e, ind) {
      return getDwellTime3(e * 1000)[ind];
    },
  },
};
</script>

<style lang="scss">
.everyday-details-dialog {
  background-color: #fff;
  border-radius: 8px;
  overflow: hidden;
  .el-dialog__header {
    font-size: 18px;
    font-weight: bold;
    height: 40px;
    line-height: 40px;
    padding: 0 24px;
    // background: #4278c9;
    .el-dialog__title {
      font-size: 16px;
      font-weight: bold;
      color: #333333;
    }
    .el-dialog__headerbtn {
      top: 11px;
      .el-dialog__close {
        font-weight: bold;
        color: #333333;
      }
    }
  }
  .el-dialog__body {
    padding: 0;
  }
  &-template {
    height: 60px;
    line-height: 60px;
    background: #f3f8ff;
    font-size: 14px;
    color: #333333;
    border-radius: 8px;
    border: 1px dashed #d1e3fe;
    padding: 0 24px;
    margin-bottom: 12px;
    &-span {
      color: #0080ff;
      cursor: pointer;
    }
  }
  &-b {
    height: auto;
    padding: 10px 24px;
    &-img {
      display: block;
      width: 40px;
      height: 40px;
      margin: 14px auto 12px;
    }
    &-text {
      text-align: center;
      font-size: 18px;
      margin-bottom: 5px;
      color: #333333;
    }
    .upload-demo {
      width: 100%;
      .el-upload {
        width: 100%;
      }
      .el-upload-dragger {
        width: 100%;
        height: auto;
        border: none;
        background-color: transparent;
      }
    }
  }
  .details-box {
    padding-top: 24px;
    &-title {
      display: flex;
      margin-bottom: 12px;
      padding-left: 24px;
      img {
        width: 96px;
        height: 96px;
        margin-right: 18px;
        margin-top: 0;
        border-radius: 4px;
      }
      &-content {
        flex: 1;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        &-status {
          height: 24px;
          line-height: 24px;
          font-size: 12px;
          color: #999999;
          padding: 0 8px;
          background: #eeeeee;
          border-radius: 2px;
          border: 1px solid #e1e1e1;
          width: max-content;
        }
        &-status1 {
          color: #00bc0d;
          background: #e5f8e6;
          border: 1px solid #b7edba;
        }
        &-zx {
          width: 100%;
          display: flex;
          align-items: center;
          justify-content: space-between;
          font-weight: bold;
          font-size: 16px;
          color: #333333;
          &-status {
            height: 28px;
            line-height: 28px;
            font-size: 14px;
            font-weight: 400;
            color: #999999;
            padding: 0 16px;
            background: #eeeeee;
            border-radius: 14px 0px 0px 14px;
            border: 1px solid #e1e1e1;
          }
          &-status2 {
            color: #00bc0d;
            background: #e5f8e6;
            border: 1px solid #b7edba;
          }
          &-status1 {
            color: #00bc0d;
            background: #e5f8e6;
            border: 1px solid #b7edba;
          }
          &-status0 {
            color: #fca118;
            background: #fff5e7;
            border: 1px solid #ffe4bd;
          }
          &-status3 {
            color: #3470ff;
            background: #eaf0ff;
            border: 1px solid #c5d6ff;
          }
        }
        &-type {
          font-size: 16px;
          color: #333333;
        }
      }
    }
  }
}
.dialog-footer {
  .cl-button {
    background: #0080ff;
    border: 1px solid #0080ff;
  }
  .is-disabled {
    opacity: 0.5;
  }
}
</style>
